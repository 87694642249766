<template>
  <div class="merchant-form">
    <div class="padding flex1">
      <Form readonly :model="form" :labelWidth="120" ref="form">
        <FormItem label="营业执照编号" prop="businessLicense">
          {{ form.businessLicense }}
        </FormItem>
        <FormItem label="营业执照" prop="businessLicensePic">
          <ImagePreview :width="70" :borderRadius="4" :datas="'/api'+form.businessLicensePic" @click="openPreview"/>
        </FormItem>
        <FormItem label="身份证正面" prop="idCardHeads">
          <ImagePreview :width="70" :borderRadius="4" :datas="'/api'+form.idCardHeads" @click="openPreview"/>
        </FormItem>
        <FormItem label="身份证反面" prop="idCardAgainst">
          <ImagePreview :width="70" :borderRadius="4" :datas="'/api'+form.idCardAgainst" @click="openPreview"/>
        </FormItem>
        <FormItem label="银行名称" prop="bankName">
          {{ form.bankName }}
        </FormItem>
        <FormItem label="开户行" prop="openingBank">
          {{ form.openingBank }}
        </FormItem>
        <FormItem label="银行卡号" prop="bankCardNo">
          {{ form.bankCardNo }}
        </FormItem>
        <FormItem label="银行卡开户人" prop="cardUserName">
          {{ form.cardUserName }}
        </FormItem>
      </Form>
    </div>
    <div class="h-modal-footer">
      <Button @click="auditShopping" color="yellow" icon="h-icon-warn" v-if="purchaseService==1">直购审核</Button>
    </div>
    <Modal v-model="modal" hasDivider :closeOnMask="false">
      <div slot="header">直购审核</div>
      <Form ref="form" :label-width="80" :model="model" :labelWidth="120" style="width: 550px" :rules="validationRules">
        <FormItem label="不通过原因" prop="purchaseReason">
          <textarea rows="3" v-autosize v-wordcount="20" v-model="model.purchaseReason"></textarea>
        </FormItem>
        <FormItem label="状态" prop="purchaseService">
          <Radio v-model="model.purchaseService" :datas="statusRadio"></Radio>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="isLoading" @click="reset">关闭</Button>
        <Button color="primary" :loading="isLoading" @click="submit">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Merchant from "@/api/Merchant";

export default {
  name: "MerchantPurchase",
  props: {
    entity: Object,
  },
  data() {
    return {
      isLoading: false,
      purchaseService: '',
      model: {
        id: this.entity.id,
        purchaseService: 3,
        purchaseReason: '',
      },
      statusRadio: {3: '通过', 2: '不通过'},
      form: {
        businessLicense: '',
        businessLicensePic: '',
        idCardHeads: '',
        idCardAgainst: '',
        bankName: '',
        cardUserName: '',
        bankCardNo: '',
        openingBank: '',
      },
      modal: false,

    }
  },
  computed: {
    validationRules() {
      let required = ['status']
      if (this.model.purchaseService == 2) {
        required.push('purchaseReason')
      }
      return {
        required: required
      }
    }
  },
  methods: {
    openPreview(data) {
      this.$ImagePreview(data);
    },
    auditShopping() {
      this.modal = true
    },
    submit() {
      Merchant.auditShopping(this.model).then(({data}) => {
        this.modal = false
        this.$emit('success');
        this.$emit('close');
      });
    },
    reset() {
      this.modal = false
    }
  },
  created() {
    if (this.entity.purchaseObject) {
      Utils.copyObj(this.form, this.entity.purchaseObject);
    }
    this.purchaseService = this.entity.purchaseService;
  }
}
</script>

<style scoped>
.merchant-form {
  width: 40vw;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
</style>
